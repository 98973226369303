import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import {
  browserTracingIntegration,
  captureRemixErrorBoundaryError,
  init as SentryInit,
  withSentry,
} from "@sentry/remix";
import { useEffect } from "react";

SentryInit({
  dsn: import.meta.env.SENTRY_DSN,
  debug: import.meta.env.DEV,
  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
});

export const ErrorBoundary = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return <div className="error">{String(error)}</div>;
};

export default withSentry(function App() {
  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
});
