import type { LoaderFunctionArgs } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { Form, useLoaderData } from "@remix-run/react";
import { useEffect, useState } from "react";

import { login, IS_SHARED_INSTANCE } from "../../shopify.server";

import styles from "./styles.module.css";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);

  if (url.searchParams.get("shop") && !IS_SHARED_INSTANCE) {
    throw redirect(`/app?${url.searchParams.toString()}`);
  }

  return json({
    showForm: Boolean(login(request.url)),
    shared: IS_SHARED_INSTANCE,
  });
};

export default function App() {
  const { shared, showForm } = useLoaderData<typeof loader>();

  const [host, setHost] = useState<string>("");
  useEffect(() => setHost(window.location.host), []);

  if (shared) {
    return (
      <div className={`${styles.index} ${styles.chrome}`}>
        <h1>Snowplow for Shopify</h1>
        <div className={styles.content}>
          <p>
            This application is the official integration for Snowplow
            implementation on Shopify storefronts, checkouts, and webhooks.
          </p>
          <p>
            To get started, enter your Shopify Domain below to begin installing
            the App on your Shopify store.
          </p>
        </div>
        {showForm && (
          <Form method="post" action="/auth/login">
            <div className={styles.content}>
              <h2>Connect to your Shopify Admin</h2>
              <label className={styles.label} htmlFor="shop">
                Shopify shop domain
              </label>
              <input
                className={styles.input}
                type="text"
                id="shop"
                name="shop"
                placeholder="test-shop1.myshopify.com"
              />
            </div>
            <button type="submit">Continue to Shopify</button>
          </Form>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.index}>
        <h1>Snowplow for Shopify</h1>
        <div className={styles.content}>
          <h2>Installation Successful!</h2>
          <p>
            This application is used with the Snowplow for Shopify app to handle
            webhook events.
          </p>
          <p>
            You will need to complete further installation steps in the Shopify
            Admin.
          </p>
          <p>
            When configuring backend event tracking, use the following URL as
            your Webhook Handler:
          </p>
          <p>{`https://${host}/`}</p>
        </div>
      </div>
    );
  }
}
